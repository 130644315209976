<template>
  <div>
    <v-alert v-if="spammed" type="error">
      <p>Nous sommes désolés: tentative de spam détectée.</p>
      <p>Contactez-moi par mail si ce n'est pas le cas.</p>
      <p>Michel</p>
    </v-alert>
    <subscription-form v-if="homophones.hasOwnProperty('currentItemCount')"></subscription-form>
    <v-row v-if="!homophones.hasOwnProperty('currentItemCount')"
            class="justify-center">
      <v-col cols="12" sm="6" >
        <v-form ref="form" v-model="valid">
          <v-card :loading ="loading" class="justify-center">
            <v-card-title class="justify-center">
              <span class="hidden-md-and-up">Trouvez les termes français similaires à votre marque:</span>
              <span class="hidden-sm-and-down headline">Trouvez les termes français similaires à votre marque:</span>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col>
                  <v-text-field
                      v-model="name"
                      label="Saisissez votre marque"
                      required
                      :rules="nameRules"
                      :error-messages="filteredErrors('name')"
                      :disabled="loading"
                      counter="10"
                      clearable
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row id="honey">
                <v-col>
                  <v-text-field
                      v-model="honey"
                      label="honey"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
                <v-btn  class="pa-2"
                        color="secondary"
                        :disabled="!valid || loading"
                        @click="getSample">Allez!</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
    <v-row v-if="homophones.hasOwnProperty('currentItemCount')
           && homophones.currentItemCount >0"
           class="justify-center">
      <v-col cols="12" sm="6" >
          <v-card>
            <v-card-title class="justify-center white--text accent1">Projet de marque "{{name}}"</v-card-title>
            <v-card-text>
              <v-row>
                <v-col>
                  <p>Nous avons trouvé <strong>{{homophones.totalItems}}</strong> termes ayant une <strong>similitude phonétique</strong> avec le terme <strong>{{name}}</strong>.</p>
                  <p>Voici un échantillon de {{homophones.currentItemCount}} valeurs possibles:</p>

                </v-col>
              </v-row>
              <v-row dense>

                <v-col cols="6" sm="4" md="3"
                       v-for="(item, index) in homophones.items" :key="index"
                       class="justify-center">{{item}}</v-col>
              </v-row>
            </v-card-text>
            <v-card-text>
              <v-row>
                <v-col>
                  <p>Nous avons trouvé <strong>{{homovisus.totalItems}}</strong> termes ayant une <strong>similitude visuelle</strong> avec le terme <strong>{{name}}</strong>.</p>
                  <p>Voici un échantillon de {{homovisus.currentItemCount}} valeurs possibles:</p>

                </v-col>
              </v-row>
              <v-row dense>

                <v-col cols="6" sm="4" md="3"
                       v-for="(item, index) in homovisus.items" :key="index"
                       class="justify-center">{{item}}</v-col>
              </v-row>
              <v-row>
                <v-col>
                  <h3>Les listes ci-dessus vous interpellent?</h3>
                  <p>Apprenez-en davantage sur les notions de <strong>similarité phonétique</strong> et de <strong>similarité visuelle</strong> en lisant les réponses aux
                  <router-link to="/questions-frequentes">questions fréquentes</router-link>.</p>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn  class="pa-2"
                      color="secondary"
                      outlined
                      :disabled="!valid || loading"
                      @click="restart">Recommencer</v-btn>
            </v-card-actions>
          </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from 'axios';
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import SubscriptionForm from '../components/SubscriptionForm';

export default {
  name: 'Home',
  components: {
    //HelloWorld
    SubscriptionForm
  },
  metaInfo() {
    return {
      title: "ma-marque française",
      meta: [
        {
          vmid: "description",
          name: "description",
          content:
            "Comment déposer une marque à l'INPI? Comment vérifier si une marque est disponible?"
        },
        {
          vmid: "keywords",
          name: "keywords",
          content:
            "dépôt de marque, INPI, marque française disponible, similitude entre deux marques, similitude phonétique, similitude visuelle"
        },

      ]
    };
  },
  data() {
    return {
      baseUrl: 'https://'
          + process.env.VUE_APP_API_SERVER_NAME
          + ':' + process.env.VUE_APP_API_SERVER_PORT,

      valid: true,
      loading: false,
      timeout: undefined,
      errors: [],
      name: '',
      nameRules: [
        v => !!v || 'Vous devez saisir votre marque',
        v => v.length >= 3 || 'La marque doit faire au moins trois caractères.',
        v => v.length <= 10 || 'La marque doit faire au maximum dix caractères.',
      ],
      homophones: {
        "apiVersion" : process.env.VUE_APP_API_SERVER_NAME,
      },
      homovisus: {
        "apiVersion" : process.env.VUE_APP_API_SERVER_NAME,
      },
      honey: '',
      spammed: false
    }
  },
  computed: {
    filteredErrors() {
      return ((field) => {
        let msg = this.errors.filter((element) => {
          return element.location.match(field)
        });
        let filtered = [];
        msg.forEach((value) => {
          filtered.push(value.message);
        });
        return filtered;
      })
    },
  },
  methods: {
    restart() {
      this.homophones = {};
      this.name = "";
    },
    async getSample() {
      console.log("getSample");
      console.log(this.name);
      console.log(this.baseUrl);


      if (this.honey != '') {
        this.valid = false;
        return this.spammed = true;
      }


      let url = this.baseUrl
        + '/homophones-sample?name='
        + this.name;

      try {
        const homophones = await axios.get(url);
            console.log({homophones});
            this.homophones = homophones.data.data;
            console.log(this.homophones);

      } catch(err) {
            if(Object.prototype.hasOwnProperty.call(err.response.data, 'error')) {
              console.log("erreur!", err.response.data.error);
              // const error = err.response.data.error;
            }
      }

      url = this.baseUrl
        + '/homovisus-sample?name='
        + this.name;

      try {
        const homovisus = await axios.get(url);
        console.log({homovisus});
        this.homovisus = homovisus.data.data;
        console.log(this.homovisus);

      } catch(err) {
        if(Object.prototype.hasOwnProperty.call(err.response.data, 'error')) {
          console.log("erreur!", err.response.data.error);
          // const error = err.response.data.error;
        }
      }

    }

/*      this.loading = true;
              setTimeout(() => {
                this.loading = false;
              },500)*/


  }
}



</script>
<style scoped>
  #honey {
    display: none;
  }

  .v-card__title {
    word-break: normal;
  }
</style>
