import Vue from 'vue'
import App from './App.vue'
import Meta from "vue-meta";

import vuetify from './plugins/vuetify';
import router from './router'

Vue.config.productionTip = false;
Vue.config.devtools = true;
Vue.use(Meta);

new Vue({
  vuetify,
  router,
  render: h => h(App)
})
  .$mount('#app');
