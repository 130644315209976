import NotFound from '../views/error/404'
import Broken from '../views/error/500'

const routes = [
  {
    httpRequestStatusCode: 500,
    path: '/500',
    name: 'Broken',
    props: true,
    component: Broken,
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFound,
  },
];

export default routes
