<template>
  <v-container fluid flex fill-height>
    <v-row align="center"
           justify="center"
    class="pa-10">
      <v-row>
      <v-col class="text-right">
        <h2 style="
          color: #BF3F57;
          font-style: italic;">
          Erreur 500
        </h2>

      </v-col>
      <v-divider
              class="mx-4"
              vertical
      ></v-divider>
      <v-col class="text-left">
        <h1>Aïe, quelque chose n'a pas fonctionné</h1>
        <p>Nous sommes désolés: il y a un problème de notre côté.</p>
        <p>Veuillez accepter nos excuses pour cette situation.</p>
        <p>Un rapport d'erreur nous a été envoyé et nous ferons le nécessaire pour corriger cela.</p>
        <!--<p>Vous pouvez retourner à la page d'accueil ou nous écrire si vous ne trouvez pas ce que vous recherchez.</p>-->
        <p><router-link to="/">Revenir à l'accueil</router-link></p>

      </v-col>
    </v-row>
      <v-row>
        <v-col>

        <v-card outlined class="mx-12">
          <v-card-title>Information technique:</v-card-title>
          <v-card-text>
            {{error.code}} : {{error.message}}
          </v-card-text>
        </v-card>
        </v-col>
      </v-row>
      </v-row>
  </v-container>
</template>
<script>
  export default {
    props: {
      error: {
        type: Object,
        default: function() {
          return {code: "500", message:"Erreur interne"};
        }
      }
    }
  }
</script>