import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import Theme from '../Theme'

Vue.use(Vuetify);

const options = {
  breakpoint: { scrollbarWidth: 12 },
  theme: {
    dark: false,
    themes: Theme,
  },
}


export default new Vuetify({
  ...options,
});
