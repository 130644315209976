import Home from '../views/Home'
import Faq from '../views/Faq'
import Subscribed from '../views/Subscribed'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/questions-frequentes',
    name: 'Faq',
    component: Faq
  },
  {
    path: '/subscribed',
    name: 'Subscribed',
    component: Subscribed
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
];

export default routes;