<template>
  <div>
    <v-row class="justify-center">
      <v-col cols="12" sm="10" md="8" lg="10">
        <h1>Questions fréquentes</h1>
        <v-expansion-panels>
          <v-expansion-panel
                  v-for="(item,i) in questions"
                  :key="i"
          >
            <v-expansion-panel-header class="accent1 white--text">
              <div v-html='item.title' ></div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div v-html='item.content'></div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
name: 'Home',
components: {
//HelloWorld
},
  metaInfo: function () {
    return {
      title: "Dépôt de marque : Questions fréquentes",
      meta: [
        {
          vmid: "description",
          name: "description",
          content:
            "Comment choisir un nom de marque disponible à l'INPI? Comment bien utiliser l'outil de recherche de marques antérieures?"
        },
        {
          vmid: "keywords",
          name: "keywords",
          content:
            "dépôt de marque française, enregistrement marque, INPI, vérifier la disponibilité d'une marque à l'INPI"
        },

      ]
    };
  },
  data: function () {
    return {
      questions: [
        {
          title: "<strong>A quoi sert cet outil</strong> de recherche approfondie de similitudes de marques françaises et quels sont les résultats fournis ?",
          content: "<p>Cet outil de recherche approfondie permet de vérifier les similitudes phonétiques et visuelles " +
            "d’un terme de dix caractères maximum avec des marques françaises déjà enregistrées à l’INPI.</p>" +
            "Il fournit, à partir d’un terme saisit par l’utilisateur un rapport de recherche comprenant:" +
            "<ul>" +
                "<li>liste des termes similaires phonétiquement générés par l’outil, en précisant le nombre total de termes générés.</li>" +
                "<li>La liste des masques de recherches de similitudes visuelles générées par l’outil en précisant le nombre total de terme générés. " +
                "Le nombre de termes similaires produits à partir des masques est en général de plus de 10 000 termes et il n’est pas prévu de les joindre.</li>" +
                "<li>La liste des résultats des recherches réalisées dans les bases de l’INPI pour l’ensemble des termes similaires " +
                "phonétiquement et similaires visuellement généré par l’outil.</li>" +
            "</ul><br/>" +
            "Pour chaque résultat, il est indiqué: " +
            "<ul>" +
                "<li>Le <strong>nom de la marque</strong>,</li>" +
                "<li>Le nom du <strong>titulaire</strong>,</li>" +
                "<li>Le <strong>statut de la marque</strong> : publiée, enregistrée,</li>" +
                "<li>Le <strong>lien vers la page</strong> de la marque sur le site de l’INPI.</li>" +
                "<li>Le <strong>logo</strong>lorsque la marque est figurative.</li>" +
            "</ul>"
        },
        {
          title: "Les <strong>résultats</strong> fournis sont-ils <strong>fiables ?</strong>",
          content: "<p>Les résultats de l’outil sont fiables : les termes générés sont contrôlés et enregistrés dans " +
            "une base de données et la recherche sur le site de l’INPI se fait terme par terme, <strong>chaque recherche est enregistrée</strong>" +
            " dans un « journal de résultats » préalablement à la production du rapport de recherche.</p>" +
            "<p>En cas d’interruption du service côté INPI, la recherche s’interrompt et reprend ensuite là où elle s’est arrêtée.</p>" +
            "<p>Naturellement, comme tout service informatique, <strong>il n’est pas à l’abri de bugs</strong> que je traite dès que j’en ai connaissance.</p>" +
            "<p>Notez que <strong>toutes les limitations</strong> qui s’appliquent sur l’outil de recherche <strong>de l’INPI se retrouvent</strong> nécessairement dans cet outil:" +
            " notamment, le délai de mise à jour des bases par rapport aux demandes récentes d’enregistrement de marques " +
            "(<a href='https://bases-marques.inpi.fr/conditions_generales_dutilisation.html' target='_blank'>voir les conditions générales d'utilisation de l'INPI</a> pour davantage de détails)."
        },
        {
          title: "Qu’est-ce que la <strong>similarité phonétique?</strong>",
          content: "<p>On parle également de comparaison phonétique ou auditive : c’est une question de prononciation.</p>" +
                  "<p>En général, les caractéristiques qui déterminent la phonétique d’un terme sont :" +
                  "<ol>" +
                    "<li>Les <strong>sonorités</strong>, en particulier le <strong>premier son</strong>, appelé " +
                    "« sonorité d’attaque » ainsi que <strong>le dernier</strong> – la « sonorité finale »,</li>" +
                    "<li>Le <strong>nombre de syllabes</strong> ou séquences : déterminer combien sont communes aux deux termes,</li>" +
                    "<li>La prosodie ou <strong>le rythme</strong> des termes.</li>" +
                  "</ol></p>" +
                  "<p>La <strong>similarité phonétique</strong> est la <strong>similitude entre les sons produits</strong>" +
                  " lorsque l’on lit deux marques distinctes.</p>" +
                  "<p>Par exemple, les termes CHAUD et CHOUX ont le son [CH] en commun et" +
                  " se distinguent par les sons [OU] et [O].</p>" +
                  "<p>Pour un humain, faire une recherche de similitude phonétique, nécessite de <strong>lire les termes " +
                  "à haute voix</strong> et de déterminer le <strong>niveau de similitude</strong> par les sons produits.</p>"
        },
        {
          title: "Qu’est-ce que la <strong>similarité visuelle?</strong>",
          content: "<p>La similarité visuelle repose sur l’appréciation de <strong>nombreux critères</strong>: </p>" +
            "<ol><li>Les <strong>polices</strong> et le <strong>style</strong> des polices de caractères,</li>" +
            "<li>Les <strong>couleurs</strong>,</li>" +
            "<li>Les <strong>dessins à proximité</strong> des termes – ce sont les éléments figuratifs,</li>" +
            "<li>Le <strong>nombre de caractères</strong> ou de signes communs,</li>" +
            "<li><strong>L’ordre</strong> et <strong>le rang</strong> des lettres,</li>" +
            "<li>L’aspect <strong>visuel global</strong> – on parle de physionomie,</li>" +
            "<li>La <strong>longueur</strong> des termes.</li>" +
            "</ol><br/>" +
            "<p>Ainsi, <em>CHAUD</em> et<em> CHOUX</em> font la <strong>même longueur</strong> de 5 lettres, ont " +
            "<strong>trois lettres en commun</strong>, dans le <strong>même ordre</strong> et dans les <strong>mêmes positions</strong>," +
            " sont dans la <strong>même police</strong>, le <strong>même style</strong> et la <strong>même couleur</strong>.</p>" +
            "<p>On peut imaginer que l’INPI considérerait que <strong>ces deux termes sont très similaires visuellement</strong>.</p>"
        },
        {
          title: "Qu’est-ce que la <strong>similarité intellectuelle?</strong>",
          content: "<p>La similarité intellectuelle est, de mon point de vue, la plus subjective.</p>" +
            "<p>En effet, il s’agit de déterminer, <strong>en se mettant dans la peau du grand public</strong>, ce qui est évoqué par chacun des termes : on recherche une <strong>similitude conceptuelle</strong>.</p>" +
            "<p>Ce concept, pour le public, est aussi porté par la <strong>notoriété</strong> éventuelle de l’un ou l’autre des termes ou des marques.</p>" +
            "<p>Il s’agit dans ce cas d’évaluer si un consommateur qui aurait en tête une marque, en voyant un terme similaire, " +
            "ferait <strong>une association entre les deux termes</strong> dans son esprit.</p>" +
            "<p>Généralement, la recherche de similarités intellectuelles se <strong>fait après avoir étudié</strong> les similitudes phonétiques et visuelles.</p>"
        },
        {
          title: "Quelles sont les <strong>similarités recherchées</strong> et <u>non recherchées par cet outil?</u>",
          content: "<p>Cet outil de recherche approfondie vise la recherche de <strong>similarités phonétiques</strong> et de <strong>similarités visuelles</strong>.</p>" +
            "<p>Cet outil <u>ne recherche pas les similarités conceptuelles ni intellectuelles</u>.</p>"
        },
        {
          title: "<strong>Qui peut utiliser</strong> cet outil?",
          content: "<p>Cet outil est mis à disposition <strong>exclusivement auprès des professionnels</strong> qui souhaitent vérifier la disponibilité" +
            "d’un nom de marque qu’ils envisagent de déposer auprès de l’INPI.</p>" +
            "<p>L’utilisation <u>est interdite pour les particuliers</u> - à l'exception de ceux exerçant avec le statut de la <strong>microentreprise</strong>, " +
            "dans la mesure où ils sont donc <strong>assimilés à des professionnels</strong>.</p>"
        },
        {
          title: "Dans quel cas ne faut-il <u>pas utiliser cet outil de recherche</u> de marques antérieures ?",
          content: "<p>La situation de chaque déposant est unique et <strong>il vous appartient de décider</strong> si vous souhaitez utiliser " +
            "ou non cet outil de recherche approfondi de marques françaises existantes et similaires à celle que vous avez " +
            "l’intention de déposer auprès de l’INPI.</p>" +
            "<p>Notez que <u>vous ne devez pas utiliser cet outil</u> dans les cas suivants :</p>" +
            "<ul>" +
              "<li>Votre nom de marque fait <u>plus de dix caractères</u></li>" +
              "<li>Vous souhaitez faire une <u>recherche sur la similarité intellectuelle</u> ou conceptuelle</li>" +
              "<li>Vous souhaitez une recherche <u>aussi exhaustive et complète que celle proposée par l’outil payant de l’INPI</u></li>" +
            "</ul><br/>" +
            "<p>Dans ces cas, je vous conseille de recourir au service de recherche approfondi de l’INPI à partir de 80€.</p>"
        }
      ]
    }
  }
}

</script>
