<template>
  <v-row class="justify-center">
    <v-col cols="12" sm="6" >
      <v-alert v-if="step === 'global-error'" type="error">Oups! Il y a eu un souci... Cet outil est encore en rodage. <br/>
        Le mieux et de me contacter directement pour débloquer cela.<br/>
        Je vous remercie.<br/>
        Michel</v-alert>
      <v-form v-if="step !== 'confirmation'" model="valid">
        <v-card :loading ="loading" class="justify-center">
          <v-card-title>
            Pour garder le contact
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col class="hidden-xs-only">
                Vous avez trouvé cet outil ludique, intéressant?<br/>
                Je vous propose de me <strong>laisser votre email</strong>: je vous previendrai de l'évolution.<br/>
                A bientôt.<br/>
                Michel
              </v-col>
              <v-col class="hidden-sm-and-up">
                Vous trouvez cet outil ludique, intéressant?<br/>
                <strong>Laissez votre email</strong>: je vous previendrai de l'évolution.<br/>
                A bientôt.<br/>
                Michel
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-text-field
                        v-model="firstname"
                        label="Saisissez votre prénom"
                        required
                        :error-messages="filteredErrors('firstname')"
                        :disabled="loading"
                        @keyup="resetErrors('firstname')"
                        counter
                        clearable
                        dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-text-field
                        v-model="email"
                        label="Saisissez votre email"
                        required
                        :error-messages="filteredErrors('email')"
                        :disabled="loading"
                        @keyup="resetErrors('email')"
                        counter
                        clearable
                        dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row id="honey">
              <v-col>
                <v-text-field
                        v-model="honey"
                        label="honey"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn  class="pa-2 white--text"
                    color="accent1"
                    :disabled="!valid || loading"
                    @click="subscribe">Inscris-moi!</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
      <v-alert v-if="step === 'confirmation'" type="success">
        C'est presque terminé...
        Il vous reste à confirmer votre adresse en cliquant sur le lien d'activiation que je veins de vous envoyer par email.
        (Pensez à regarder aussi dans vos spams)
      </v-alert>
    </v-col>
  </v-row>
</template>

<script>
  import axios from 'axios';

  export default  {
    name: 'SubscriptionForm',
    data() {
      return {
        baseUrl: 'https://'
          + process.env.VUE_APP_API_SERVER_NAME
          + ':' + process.env.VUE_APP_API_SERVER_PORT,

        valid: true,
        loading: false,
        step: 'initialisation',
        errors: [],
        firstname: '',
        email: '',
        honey: '',
        spammed: false
      }
    },
    computed: {
      filteredErrors() {
        return ((field) => {
          let msg = this.errors.filter((element) => {
            return element.location.match(field)
          });
          let filtered = [];
          msg.forEach((value) => {
            filtered.push(value.message);
          });
          return filtered;
        })
      },
    },
    methods: {
      subscribe() {

        this.loading = true;
        // console.log("subscribe");
        // console.log(this.name);
        // console.log(this.baseUrl);
        let url = this.baseUrl
          + '/users/subscribe';

        if (this.honey !== '') {
          this.valid = false;
          return this.spammed = true;
        }

        let postData = {
          apiVersion: process.env.VUE_APP_API_VERSION,
          context: 'subscribe',
          method: 'users.subscribe',
          data: {
            totalItems: 1,
            items: [
              {
                firstname: this.firstname,
                email: this.email,
              },
            ]
          },
        };

        axios.post(url, postData)
          .then(() => {
          // .then((res) => {
            // console.log("resultat", res);
            this.step = 'confirmation';

          })
          .catch((err) => {
            // console.log("erreur", err);
            if (Object.prototype.hasOwnProperty.call(err.response.data, 'error')) {
              this.errors = err.response.data.error.errors;
            } else {
              //global error
              this.step = 'global-error';
            }
          })
          .finally(() => {
            this.loading = false;
          });
      },
      resetErrors(field) {
        console.log("resetErrors: ", field);
        this.errors = this.errors.filter((element) => {
          return !element.location.match(field)
        });
      },
    }
  }
</script>

<style scoped>
  #honey {
    display: none;
  }
</style>