import Vue from 'vue'
import VueRouter from 'vue-router'

import PublicRoutes from './public'
import ErrorRoutes from './error'


Vue.use(VueRouter);

const routes = PublicRoutes
  .concat(ErrorRoutes);


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router
